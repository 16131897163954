import React from 'react';
import './App.scss';
import GuardPage from './Pages/GuardPage';

function App() {
  return (
    <div className="App">
      <GuardPage/>
    </div>
  );
}

export default App;
